import { PageShuttleMain, useRouter } from '@/router'
import { useCallback, useMemo } from 'react'
import { useFetchCategories } from '@/services/hooks/queries/shuttle-category/useFetchCategories'
import { Category } from '@/services/models/bus-api/v1/category'

interface UseShuttleCategoryController {
  categoryOptions: Array<{ label: string; value: Category }>
  selectedCategory: Category
  onSelectCategoryId: (categoryId?: number) => Promise<void>
}

const defaultCategory: Category = {
  id: undefined,
  order: 0,
  categoryName: '전체',
  exposed: true,
}

const useCategoryController = (): UseShuttleCategoryController => {
  const router = useRouter()
  const { data: categoriesData } = useFetchCategories()

  const categoryOptions = useMemo(() => {
    return [defaultCategory, ...(categoriesData ?? [])]?.map((category) => ({
      label: category.categoryName,
      value: category,
    }))
  }, [categoriesData])

  const selectedCategory = useMemo(() => {
    const selectedCategoryId = router.query?.categoryId
    return (
      categoryOptions.find(
        ({ value }) => value.id === Number(selectedCategoryId)
      )?.value ?? defaultCategory
    )
  }, [categoryOptions, router.query, categoriesData])

  const onSelectCategoryId = useCallback(
    async (categoryId?: number) => {
      await router.replace(
        PageShuttleMain.toString({
          query: { ...(router?.query ?? {}), categoryId },
        })
      )
    },
    [router.query]
  )

  return {
    categoryOptions,
    selectedCategory,
    onSelectCategoryId,
  }
}

export default useCategoryController
