import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setLoaded, setLoading } from '@/store/modules/loader'

const useLoading = (deps: boolean[]): void => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (deps.filter((dep) => dep).length) dispatch(setLoading())
    return () => {
      dispatch(setLoaded())
    }
  }, [...deps, dispatch])
}

export default useLoading
