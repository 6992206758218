import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { BubbleTabs } from '@kakaomobility/tui-react-maas/dist/maas'
import { TColors } from '@kakaomobility/tui-react-maas/dist/shuttle'
import useShuttleCategoryController from '@/hooks/shuttles/home/useShuttleCategoryController'

import ProductInfoPanel from '@/components/shuttles/common/ProductInfoPanel'
import { Category } from '@/services/models/bus-api/v1/category'
import { createLogClickEvent } from '@/helpers/logger'
import { LogEventName, LogPageName } from '@/enums/logger'

const Styled = {
  Wrapper: styled.div`
    background: ${TColors.PRIMARY2};
    padding: 8px 0 16px;
  `,
  Tabs: styled(BubbleTabs)`
    margin-bottom: 24px;
  `,
}

const logClickEvent = createLogClickEvent(LogPageName.SHUTTLE_MAIN)

function CategoryTabs(): ReactElement {
  const { categoryOptions, selectedCategory, onSelectCategoryId } =
    useShuttleCategoryController()

  const handleTabOnChange = async (category: Category): Promise<void> => {
    await onSelectCategoryId(category?.id)
    logClickEvent(
      LogEventName.SHUTTLE_HOME_CATEGORY_CLICK,
      {},
      {},
      {
        category: category.categoryName,
      }
    )
  }

  return (
    <Styled.Tabs
      value={selectedCategory}
      options={categoryOptions}
      onChange={handleTabOnChange}
    />
  )
}

interface ShuttleMainProductControllerProps {
  className?: string /* for emotion */
  totalCount?: number
}

export default function ShuttleMainProductController({
  className,
  totalCount,
}: ShuttleMainProductControllerProps): ReactElement {
  return (
    <Styled.Wrapper className={className}>
      <CategoryTabs />
      <ProductInfoPanel totalCount={totalCount} />
    </Styled.Wrapper>
  )
}
