import instance from '@/services/instance'
import { Category } from '@/services/models/bus-api/v1/category'
import { FetcherTupleConstructor } from '@/services/hooks/queryBuilder'
import { useQuery, UseQueryResult } from 'react-query'

interface GetCategoriesParams {
  name?: string /* 특정 카테고리를 찾을 때만 사용 */
}

export const getCategoriesFetcherTuple: FetcherTupleConstructor<
  Category[],
  GetCategoriesParams
> = (settings) => [
  ['v1/category', settings?.params?.name ?? ''],
  async () =>
    (await instance.get('/bus-api/v1/category', { params: settings?.params }))
      ?.data,
]

export const useFetchCategories = (
  params?: GetCategoriesParams
): UseQueryResult<Category[]> => {
  return useQuery(...getCategoriesFetcherTuple({ params }))
}
