import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import {
  FONT_12,
  FONT_16_BOLD,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import * as Icons from '@kakaomobility/tui-icons-react'
import { createLogClickEvent } from '@/helpers/logger'
import { LogEventName, LogPageName } from '@/enums/logger'
import { Hidden } from '@kakaomobility/tui-react'

const Styled = {
  wrap: styled.a`
    display: block;
    width: 100%;
    background-color: ${TColors.PRIMARY2};
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;
  `,
  container: styled.span`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  `,
  textWrap: styled.span`
    display: block;
    text-align: left;
  `,
  title: styled.b`
    display: block;
    ${FONT_16_BOLD};
    margin-bottom: 8px;
  `,
  subtitle: styled.span`
    display: block;
    ${FONT_12};
    color: ${TColors.NEUTRAL2};
  `,
}

const logShuttleMainClickEvent = createLogClickEvent(LogPageName.SHUTTLE_MAIN)

interface TravelInquiryBannerButtonProps {
  className?: string
}

function TravelInquiryBannerButton({
  className,
}: TravelInquiryBannerButtonProps): ReactElement {
  const handleClick = (): void => {
    logShuttleMainClickEvent(LogEventName.SHUTTLE_BANNER_CLICK_3)
  }
  return (
    <Styled.wrap
      className={className}
      target='_blank'
      href={`https://docs.google.com/forms/d/1lP0O7ZhPuZsjnCHMkTsGcs46sfmcF2QGBxmYeeFyckc/edit`}
      onClick={handleClick}
    >
      <Styled.container>
        <Styled.textWrap>
          <Styled.title>여행 셔틀 문의</Styled.title>
          <Styled.subtitle>
            레저시설로의 이동, 투어 여행을 위한
            <br />
            셔틀이 필요하신가요?
          </Styled.subtitle>
        </Styled.textWrap>
        <Icons.I52CategoryShuttle />
      </Styled.container>
      <Hidden>새창열림</Hidden>
    </Styled.wrap>
  )
}

export default TravelInquiryBannerButton
