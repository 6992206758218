import React, { ReactElement, useEffect } from 'react'
import styled from '@emotion/styled'
import { LayoutFrame, LayoutHeader } from '@/components/layouts'
import {
  CustomNextPage,
  PageError,
  PageShuttleItem,
  PageUiSandbox,
  useRouter,
} from '@/router'
import { logPageViewEffect } from '@/helpers/logger'
import { LogPageName } from '@/enums/logger'
import generateKey from '@/helpers/generateKey'
import {
  ButtonThemeType,
  TButton,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import { FocusChildOnMore } from '@kakaomobility/tui-react-maas/dist/maas'
import ShuttleCardList from '@/components/shuttles/ShuttleCardList'
import TravelInquiryBannerButton from '@/components/shuttles/banner/TravelInquiryBannerButton'
import CityTourBannerButton from '@/components/shuttles/banner/CityTourBannerButton'
import {
  numberWithComma,
  useBackHandler,
} from '@kakaomobility/tui-react-maas/dist'
import {
  AppWebviewSchemeType,
  createWebViewSchemeHandler,
} from '@/helpers/appSchemeHelper'
import withSSR from '@/helpers/ssr/withSSR'
import { convertThumbImageUrl } from '@/helpers/convertThumbImageUrl'
import { useAppDispatch } from '@/store'
import { setSentFrom } from '@/store/modules/tiara'
import { ShuttleDisplayCardInfo } from '@/services/models/bus-api/v1/item/display'
import FestivalShuttleBannerButton from '@/components/shuttles/banner/FestivalShuttleBannerButton'
import ShuttleMainProductController from '@/components/shuttles/home/ShuttleMainProductController'
import * as Icons from '@kakaomobility/tui-icons-react'
import { getCategoriesFetcherTuple } from '@/services/hooks/queries/shuttle-category/useFetchCategories'
import {
  getInfiniteHomeItemsFetcherTuple,
  useFetchInfiniteHomeItems,
} from '@/services/hooks/queries/shuttle-items/useFetchInfiniteHomeItems'
import useLoading from '@/hooks/useLoading'
import { SalesStatus } from '@/services/models/common'
import appBridge from '@kakaomobility/app-bridge'
import { Hidden } from '@kakaomobility/tui-react'

const Styled = {
  Wrap: styled.div`
    padding-bottom: 56px;
  `,
  CardWrapper: styled(FocusChildOnMore)`
    padding: 20px 20px 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  `,
  ButtonWrapper: styled.div`
    margin-bottom: 40px;
    padding: 0 20px 0;
  `,
  BannerWrapper: styled.div`
    display: grid;
    padding: 0 20px 0;
    gap: 16px;
  `,
  Button: styled(TButton)`
    height: 48px;
    border-color: ${TColors.NEUTRAL6};
    &:after {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 4px;
      background-image: url(${Icons.I16FoldUri});
    }
  `,
}

export const getServerSideProps = withSSR(
  async ({ queryClient, apolloProxy, sentry, query, logger }) => {
    try {
      await Promise.all([
        queryClient.fetchInfiniteQuery(
          ...getInfiniteHomeItemsFetcherTuple(query)
        ),
        queryClient.fetchQuery(...getCategoriesFetcherTuple()),
      ])
      /* bug solution with fetchInfiniteQuery */
      queryClient.setQueryData(
        getInfiniteHomeItemsFetcherTuple(query)[0],
        (data: any) => ({
          ...(data ?? {}),
          pageParams: [],
        })
      )
      return {
        props: {
          query,
        },
        meta: {
          title: `셔틀 홈 | 카카오 T`,
        },
      }
    } catch (e: any) {
      sentry.setContext('error', { content: JSON.stringify(e) })
      sentry.captureException(e)
      return {
        redirect: {
          destination: PageError.toString({
            query: {
              message: e.msg,
              errorCode: e.code,
            },
          }),
          permanent: false,
        },
      }
    }
  }
)

const ShuttleMain: CustomNextPage<{
  query: Record<string, string>
}> = ({ query }): ReactElement => {
  useBackHandler(() => {
    createWebViewSchemeHandler(AppWebviewSchemeType.CLOSE)()
    return true
  }, appBridge)
  const router = useRouter()
  const { items, totalCount, fetchNextPage, hasNextPage, isFetching } =
    useFetchInfiniteHomeItems(query)
  useLoading([isFetching])

  const dispatch = useAppDispatch()
  useEffect(() => {
    const sentFrom = query?.sentFrom ?? ''
    dispatch(setSentFrom(sentFrom))
    const logPageViewEffectCallback = logPageViewEffect(
      LogPageName.SHUTTLE_MAIN,
      sentFrom ? { id: sentFrom, type: sentFrom } : undefined
    )
    return logPageViewEffectCallback()
  }, [])

  const handleClickCardItem = async (
    item: ShuttleDisplayCardInfo
  ): Promise<void> => {
    if (!item.id) return
    await router.push(
      PageShuttleItem.toString({ params: { shuttleItemId: `${item.id}` } })
    )
  }

  return (
    <>
      <Styled.Wrap>
        <Hidden as={'h2'}>T셔틀 상품 목록</Hidden>
        <ShuttleMainProductController />
        <Styled.CardWrapper as={'div'}>
          {items?.map((item, index) => {
            return (
              <ShuttleCardList
                key={generateKey('channelingShuttleList', index)}
                availableBooking={item.availableBooking}
                image={convertThumbImageUrl(item?.imageUrl)}
                title={item?.displayTitle ? item?.displayTitle : item.name}
                description={item.displayDeparture ?? ''}
                price={
                  item.salesStatus
                    ? item.salesStatus === SalesStatus.ON_SALE
                      ? `${numberWithComma(item.displayPrice)}~`
                      : '판매 예정'
                    : ''
                }
                status={item.salesStatus}
                onClick={async () => await handleClickCardItem(item)}
                hasDescriptionClamp={!!item.id}
              />
            )
          })}
        </Styled.CardWrapper>
        {hasNextPage && (
          <Styled.ButtonWrapper>
            <Styled.Button
              type='button'
              btnTheme={ButtonThemeType.OUTLINE}
              onClick={async () => await fetchNextPage()}
            >
              더보기
            </Styled.Button>
          </Styled.ButtonWrapper>
        )}
        <Styled.BannerWrapper>
          <Hidden as={'h2'}>배너</Hidden>
          <CityTourBannerButton />
          <FestivalShuttleBannerButton />
          <TravelInquiryBannerButton />
        </Styled.BannerWrapper>
      </Styled.Wrap>
    </>
  )
}

ShuttleMain.getLayout = (page: ReactElement, { router }): ReactElement => {
  const header = (
    <LayoutHeader
      propsBuilder={({ backPropsBuilder, titlePropsBuilder }) => ({
        backIconType: backPropsBuilder.icon.back,
        onBack: backPropsBuilder.handler.close,
        title: 'T셔틀',
        closeIconType: {
          label:
            process.env.NEXT_PUBLIC_BUILD_ENV !== 'production'
              ? 'debug'
              : undefined,
        },
        onClose: async () => {
          await router.push(PageUiSandbox)
        },
      })}
    />
  )
  return (
    <LayoutFrame header={header}>
      <>{page}</>
    </LayoutFrame>
  )
}

export default ShuttleMain
