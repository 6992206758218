import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import { PageShuttleFilterTags } from '@/router'
import {
  TColors,
  FONT_16_BOLD,
  FONT_12,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import * as Icons from '@kakaomobility/tui-icons-react'
import { createLogClickEvent } from '@/helpers/logger'
import { LogEventName, LogPageName } from '@/enums/logger'

const Styled = {
  wrapper: styled.span`
    display: block;
  `,
  btn: styled.a`
    display: flex;
    align-items: center;
    padding: 16px;
    position: relative;
    text-align: left;
    background-color: #ffe483;
    border-radius: 8px;
  `,
  title: styled.b`
    display: block;
    ${FONT_16_BOLD};
    color: ${TColors.NEUTRAL1};
    margin-bottom: 8px;
  `,
  subtitle: styled.span`
    display: block;
    ${FONT_12};
    line-height: 1;
    color: ${TColors.NEUTRAL2};
    display: flex;
    align-items: center;
    > svg {
      margin-left: 4px;
    }
  `,
  icon: styled(Icons.I52CategoryCitytour)`
    flex-shrink: 0;
    margin-left: auto;
  `,
}

const logShuttleMainClickEvent = createLogClickEvent(LogPageName.SHUTTLE_MAIN)

interface CityTourBannerButtonProps {
  className?: string /* for emotion */
}

export default function CityTourBannerButton({
  className,
}: CityTourBannerButtonProps): ReactElement {
  const handleClick = (): void => {
    logShuttleMainClickEvent(LogEventName.SHUTTLE_BANNER_CLICK_1)
  }
  return (
    <Styled.btn
      onClick={handleClick}
      className={className}
      href={PageShuttleFilterTags.toString({ query: { tag: '시티투어셔틀' } })}
    >
      <Styled.wrapper>
        <Styled.title>
          시티투어 버스 타고
          <br />
          국내 도시여행 함께 떠나요!
        </Styled.title>
        <Styled.subtitle>
          지금 예약하러가기 <Icons.I16ArrowRight />
        </Styled.subtitle>
      </Styled.wrapper>
      <Styled.icon label={''} />
    </Styled.btn>
  )
}
