import React, { ReactElement } from 'react'
import styled from '@emotion/styled'
import * as Icons from '@kakaomobility/tui-icons-react'
import {
  FONT_12,
  FONT_16_BOLD,
  TColors,
} from '@kakaomobility/tui-react-maas/dist/shuttle'
import { createLogClickEvent } from '@/helpers/logger'
import { LogEventName, LogPageName } from '@/enums/logger'
import { Hidden } from '@kakaomobility/tui-react'

const Styled = {
  Button: styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: ${TColors.PRIMARY2};
    border-radius: 8px;
  `,
  Wrapper: styled.span`
    display: block;
  `,
  Title: styled.b`
    display: block;
    ${FONT_16_BOLD};
    color: ${TColors.NEUTRAL1};
    margin-bottom: 8px;
  `,
  SubTitle: styled.span`
    display: block;
    ${FONT_12};
    color: ${TColors.NEUTRAL2};
  `,
}
const { Button, Wrapper, Title, SubTitle } = Styled

const logShuttleMainClickEvent = createLogClickEvent(LogPageName.SHUTTLE_MAIN)

interface FestivalShuttleBannerButtonProps {
  className?: string
}

export default function FestivalShuttleBannerButton({
  className,
}: FestivalShuttleBannerButtonProps): ReactElement {
  const handleClick = (): void => {
    logShuttleMainClickEvent(LogEventName.SHUTTLE_BANNER_CLICK_2)
  }
  return (
    <Button
      className={className}
      href={
        'https://docs.google.com/forms/d/1DRq6IVprAmQwlEQdQpfCwbh7Ws9QlI5Cwnc4xa-FvLI/edit'
      }
      target='_blank'
      rel='noopener noreferrer'
      onClick={handleClick}
    >
      <Wrapper>
        <Title>페스티벌 셔틀 문의</Title>
        <SubTitle>
          뮤직, 지역 축제 등 대규모 행사에 셔틀이 <br /> 필요하신가요?
        </SubTitle>
      </Wrapper>
      <Icons.I52CategoryFestival />
      <Hidden>새창열림</Hidden>
    </Button>
  )
}
